<template>

  <div class="register profile__detail">

    <p class="text-center">Auf Basis deiner Auswahl schlagen wir dir individuell für deine Sportarten passende Playlists & Workouts vor.</p>

    <div class="selection-cards">
      <div class="selection-cards__item" :key="sports_type.id" v-for="sports_type in filtered_sports_types">
        <input :id="sports_type.name" :name="sports_type.name" v-model="sports_type.checked" type="checkbox" value="0">
        <label :for="sports_type.name">
            <div class="selection-cards__item__label">{{ sports_type.name }}</div>
        </label>
      </div>
    </div>
    
    <v-btn class="button button--primary" @click="saveSportsTypes">Übernehmen</v-btn>

  </div>
</template>

<script>
import firebase from 'firebase';

export default {
    name: "SettingsSportsTypes",
    data() {
        return {
            sports_types: [],
            selected_sports_types: [],
            userInformation: null,
            user: null,
        }
    },
    methods: {
        init(){
            this.user = firebase.auth().currentUser;

            this.$store.dispatch('fetchUserInformation').then( (res) => {
                if (res.length != 0) {
                    this.userInformation = res[0];
                }
                this.processSportsTypes();
            });
        },
        processSportsTypes() {
            this.$store.dispatch('fetchSportsTypes').then( () => {
                this.sports_types = this.$store.getters.getSportsTypes;
                this.sports_types.forEach((st) => st.checked = false);

                if (this.userInformation && this.userInformation.sports_types) {
                    this.userInformation.sports_types.forEach(st => {
                        this.sports_types.find(s => s.id == st.id).checked = true;
                    });
                }
            });
        },
        saveSportsTypes() {
            if (this.userInformation == null) {
                this.userInformation = {}
            }

            this.sports_types.forEach((st) => {
                if (st.checked) { this.selected_sports_types.push(st); }
            });

            this.userInformation.sports_types = this.selected_sports_types;

            this.$store.dispatch('saveUserInformation', this.userInformation).then( () => { this.$router.go(-1); } );
            //this.$router.go(-1);
        },
        getSportsTypeImage(sports_type_name, active) {
            sports_type_name = sports_type_name.toLowerCase();
            sports_type_name = sports_type_name.replace(/ä/g, 'ae');
            sports_type_name = sports_type_name.replace(/ö/g, 'oe');
            sports_type_name = sports_type_name.replace(/ü/g, 'ue');
            sports_type_name = sports_type_name.replace(/ß/g, 'ss');
            sports_type_name = sports_type_name.replace(/ /g, '_');
            sports_type_name = sports_type_name.replace(/\./g, '');
            sports_type_name = sports_type_name.replace(/,/g, '');
            sports_type_name = sports_type_name.replace(/\(/g, '');
            sports_type_name = sports_type_name.replace(/\)/g, '');

            try {
                if (active) {
                    return require('@/assets/ic_32_sport_'+ sports_type_name + '-active.svg');
                }
                return require('@/assets/ic_32_sport_'+ sports_type_name + '.svg');
                }
            catch(error) {
                return "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
            }
        },
    },
    computed: {
        filtered_sports_types() {
            return this.sports_types.filter(st => st.user_selectable);
        }
    },
    mounted() {
        this.init();
    },
}
</script>